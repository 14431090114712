<template>
    <div @click.stop="$emit('callback')">
        <fade-in-image
            class="fade-in-img"
            :src="item.images[0]"
            placeholder="/img/car-placeholder.svg"
            width="75px"
            height="56px" />
    </div>
</template>

<script>
import FadeInImage from '@/components/globals/FadeInImage';

export default {
    components: {
        FadeInImage
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
};
</script>
<style scoped>
.fade-in-img {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
    cursor: pointer;
}
</style>